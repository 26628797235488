import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

//Servicio para traer cuentas de correo con filtros por parametros
export const getCuentasCorreoListByParams = async (params) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
    params:{
      ...params
    }
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/",
      config
    );
    
    return serverResponse.data

};

//Listado de Tipos de cuentas
export const getAccountTypes = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/tiposCuenta",
      config
    );
    
    return serverResponse.data

};

//Listado de usuarios que pueden ser responsables de cuentas
export const getEmailsOwners = async (params) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
    params:{
      ...params
    }
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/responsableCuenta",
      config
    );
    
    return serverResponse.data

};

//Listado de procesos de solicitudes por area de negocio
export const BusinessProcess = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/procesosNegocio",
      config
    );
    
    return serverResponse.data

};

//Listado de procesos de solicitudes por area de negocio
export const postCrearCuentaCorreo = async (form) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/cuentasCorreo/",
      form,
      config
    );
    
    return serverResponse.data

};

//Detalles de una cuenta de correo
export const getCuentaCorreoById = async (id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/"+id,
      config
    );
    
    return serverResponse.data

};

//Servicio de detalle de edicion de cuenta de correo por id 
export const editCuentaCorreoByID = async (form,id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/cuentasCorreo/"+id,
      form,
      config
    );
    
    return serverResponse.data

};

//Servicio de listado de estatus de cuentas de email
export const getListadoEstatusEmail = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/cuentasCorreo/status",
      config
    );
    
    return serverResponse.data

};


export const serverImapService = 
  [
    {
      "proveedor": "Gmail",
      "servidor": "imap.gmail.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "Outlook.com",
      "servidor": "imap-mail.outlook.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "Yahoo Mail",
      "servidor": "imap.mail.yahoo.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "iCloud Mail",
      "servidor": "imap.mail.me.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "AOL Mail",
      "servidor": "imap.aol.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "Zoho Mail",
      "servidor": "imap.zoho.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "Yandex Mail",
      "servidor": "imap.yandex.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "GMX Mail",
      "servidor": "imap.gmx.com",
      "puerto": 993,
      "seguridad": "SSL"
    },
    {
      "proveedor": "Mail.com",
      "servidor": "imap.mail.com",
      "puerto": 993,
      "seguridad": "SSL"
    }
  ];

