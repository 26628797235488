<template>
  <v-dialog
    v-model="dialog"
    v-if="dialog"
    persistent
    max-width="1500"
    :scrollable="true"
  >
    <v-card class="pa-0" style="height: 100%;">
      <v-card-title>
        <span class="text-h5"> {{ readOnly ? 'Ver' : ( cuentaId ? 'Editar' : 'Crear')  }} cuenta de correo</span>
      </v-card-title>
      <v-card-text style="padding: 20px;">
        <CreateCorreo
          :toSave="toSave"
          @goTosave="goTosave"
          :cuentaId="cuentaId"
          :readOnly="readOnly"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded color="#00a7e4" @click="$emit('closeModal')">
          <h4>
            Cerrar
          </h4>
        </v-btn>
        <v-btn
          color="#00a7e4"
          class="common-botton"
          @click="toSave = true"
          v-if="!readOnly"
        >
          <h4 style="color: white;">
            Guardar
          </h4>
        </v-btn>
      </v-card-actions>
      <CommonActionModal
        :dialog="dialogEleccion"
        title="Creación de cuenta de correo"
        subtitle="¿Deseas crear la cuenta de correo?"
        bottonText="Si, crealo"
        @userChoice="postPutCuentaCorreo"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CreateCorreo from "@/components/commonComponents/forms/CreateCorreo.vue";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import { postCrearCuentaCorreo,editCuentaCorreoByID } from "@/services/cuentasCorreo/cuentasCorreo.service.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    cuentaId: {
      type: Number,
      default: null,
    },
  },
  components: {
    CreateCorreo,
    CommonActionModal,
  },
  data() {
    return {
      toSave: false,
      dialogEleccion: false,
      form: {},
    };
  },
  methods: {
    goTosave(result, form) {
      if (result) this.dialogEleccion = true;
      this.form = form;
      this.toSave = false;
    },
    postPutCuentaCorreo(val) {
      this.dialogEleccion = false;
      if (val) {
        if (!this.cuentaId) this.postCrearCuentaCorreo();
        else this.editCuentaCorreoByID();
      }
    },
    async postCrearCuentaCorreo() {
      const finalform = {
        ...this.form,
        reglaNegocioAplicable: String(this.form.reglaNegocioAplicable),
      };

      try {
        await postCrearCuentaCorreo(finalform);
        this.$toast.success("Cuenta correo creado con éxito");
        this.$emit("closeModal");
        this.dialogEleccion = false;
      } catch (error) {
        this.$toast.error("Ocurrió un error al crear la cuenta de correo");
        this.dialogEleccion = false;
      }
    },
    async editCuentaCorreoByID() {
      const finalform = {
        ...this.form,
        reglaNegocioAplicable: String(this.form.reglaNegocioAplicable),
      };

      try {
        await editCuentaCorreoByID(finalform, this.cuentaId);
        this.$toast.success("Cuenta correo editada con éxito");
        this.$emit("closeModal");
        this.dialogEleccion = false;
      } catch (error) {
        this.$toast.error("Ocurrió un error al crear la cuenta de correo");
        this.dialogEleccion = false;
      }

    },
  },
};
</script>
