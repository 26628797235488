<template>
  <common-card style="padding-top: 5px;">
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Administracion de correos</h1>
          </v-col>
          <v-col cols="12" md="6" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="openModal()"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              <span>Crear</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <FiltrosEmail @updateFilters="updateFilters" @restartFiltros="clearFilters" />
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          :headers="capaingsTable"
          :items="cuentasCorreo"
          item-key="name"
          class="elevation-0"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
          :server-items-length="total"
          @update:page="page = $event"
          @update:items-per-page="size = $event"
        >
          <template v-slot:top>
            <v-row style="padding: 15px;">
              <v-col cols="8">
                <h2>Resultados de la búsqueda</h2>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-btn
              icon
              @click="$router.push('/detalle-cuenta-correo/'+item.id)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <CreateCorreosModal
      v-if="dialog"
      :dialog="dialog"
      :cuentaId="cuentaId"
      :readOnly="readOnly"
      @closeModal="closeModalCorreos"
    />
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import {
  getCuentasCorreoListByParams
} from "@/services/cuentasCorreo/cuentasCorreo.service.js";
import FiltrosEmail from "./FiltrosEmail.vue";
import CreateCorreosModal from "../../../../components/commonComponents/dialogs/CreateCorreosModal.vue";

export default {
  components: {
    CommonCard,
    FiltrosEmail,
    CreateCorreosModal,
  },
  data() {
    return {
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      capaingsTable: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Cuenta",
          align: "start",
          sortable: false,
          value: "cuenta",
        },
        { text: "Estatus", value: "estatus" },
        { text: "Área de Negocio", value: "areaNegocio" },
        { text: "Responsable", value: "responsable" },
        { text: "Fecha Creación", value: "fechaCreacion" },
        { text: "Ver", value: "acciones" },
      ],
      report: [],
      cuentasCorreo: [],
      filtros: {
        cuenta: "",
        areaNegocioId: "",
        estatus: "",
        responsableCuentaId: "",
      },
      page: 1,
      size: 10,
      total: 0,
      dialog: false,
      route: "",
      readOnly:false,
      cuentaId:null
    };
  },

  computed: {},
  watch: {
    page(v) {
      if (v) this.pagina = v;
      this.getCuentasCorreoListByParams();
    },
    size(v) {
      if (v > 0) this.size = v;
      else this.size = this.total;

      this.getCuentasCorreoListByParams();
    },
  },
  methods: {
    async getCuentasCorreoListByParams(restartPagination = false) {
      this.loading = true;
      var finalFilters = {};
      Object.keys(this.filtros).map((e) => {
        if (this.filtros[e]) finalFilters[e] = this.filtros[e];
      });
      var body = {
        ...finalFilters,
        ...{
          pagina: !restartPagination ? this.page : 1,
          elementosPagina: !restartPagination ? this.size : 10,
        },
      };
      var response = await getCuentasCorreoListByParams(body);
      this.cuentasCorreo = response.cuentas;
      this.total = response.count;
      this.loading = false;
    },
    clearFilters() {
      Object.assign(this.filtros, {
        cuenta: "",
        areaNegocioId: "",
        estatus: "",
        responsableCuentaId: "",
      });
      this.getCuentasCorreoListByParams(true);
    },
    openModal() {
      this.dialog = true;
    },
    successModal() {
      this.dialog = false;
      this.clearFilters();
    },
    async openCorreoDetalles(cuentaCorreoId, readOnly = false) {
      this.cuentaId = cuentaCorreoId
      this.readOnly = readOnly
      this.dialog = true
    },
    closeModalCorreos(){
      this.dialog = false
      this.cuentaId = null
      this.readOnly = false
      this.getCuentasCorreoListByParams(true);
    },
    updateFilters(filters){
      this.filtros = filters
      this.getCuentasCorreoListByParams();
    }
  },
  mounted() {
    this.getCuentasCorreoListByParams();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
