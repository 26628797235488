<template>
  <div>
    <v-form ref="cuentaCorreo">
      <v-row>
        <v-col cols="12" md="3">
          <v-autocomplete
            label="Tipo de Cuenta"
            v-model="form.tipoCuenta"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :items="tiposCuenta"
            :readonly="readOnly"
            :rules="required"
            item-text="tipo"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-text-field
            label="Usuario"
            v-model="form.cuenta"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
            autocomplete="new-username"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-text-field
            label="Contraseña"
            v-model="form.secretId"
            primary
            type="password"
            color="#00a7e4"
            outlined
            :readonly="readOnly"
            :rules="required"
            autocomplete="new-password"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-autocomplete
            label="Servidor IMAP"
            v-model="form.servidor"
            :items="serverImapService"
            item-text="proveedor"
            item-value="servidor"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-text-field
            label="Tipo de seguridad"
            :rules="required"
            v-model="form.tipoSeguridad"
            outlined
            primary
            :readonly="readOnly"
            color="#00a7e4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-text-field
            label="Tipo de autentificación"
            :rules="required"
            v-model="form.tipoAutentificacion"
            outlined
            primary
            :readonly="readOnly"
            color="#00a7e4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-text-field
            label="Puerto"
            :rules="required"
            v-model="form.puerto"
            outlined
            primary
            :readonly="readOnly"
            color="#00a7e4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="form.tipoCuenta != null">
          <v-autocomplete
            label="Área de Negocio"
            v-model="form.areaNegocioId"
            :items="areaNegocios"
            item-value="id"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
          >
            <template v-slot:item="{ item }">
              <div>
                <strong>{{ formatText(item) }}</strong>
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <span>
                {{ formatText(item) }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="form.tipoCuenta != null && form.areaNegocioId != null"
        >
          <v-autocomplete
            label="Responsable de la cuenta"
            v-model="form.responsableCuenta"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :items="responsableDeCuentas"
            item-text="nombre"
            item-value="id"
            :readonly="readOnly"
            :rules="required"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="form.tipoCuenta != null && form.areaNegocioId != null"
        >
          <p>Servicio de Creación de Solicitudes</p>
          <v-radio-group
            v-model="form.servicioCreacionSolicitudes"
            row
            :rules="required"
            :readonly="readOnly"
          >
            <v-radio value="true">
              <template v-slot:label>
                <div>
                  Si claro
                </div>
              </template>
            </v-radio>
            <v-radio value="false">
              <template v-slot:label>
                <div>
                  Este caso no.
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="
            form.servicioCreacionSolicitudes == 'true' &&
              form.areaNegocioId != null
          "
        >
          <v-autocomplete
            label="Regla de negocio a aplicar"
            v-model="form.reglaNegocioAplicable"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :items="currentReglasNegocio"
            item-text="proceso"
            item-value="id"
            :readonly="readOnly"
            :rules="required"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="form.tipoCuenta != null && form.areaNegocioId != null"
        >
          <p>Servicio de Notificaciones Salientes</p>
          <v-radio-group
            v-model="form.servicioNotificacionesSalientes"
            row
            :rules="required"
            :readonly="readOnly"
          >
            <v-radio value="true">
              <template v-slot:label>
                <div>
                  Si claro
                </div>
              </template>
            </v-radio>
            <v-radio value="false">
              <template v-slot:label>
                <div>
                  Este caso no.
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { getAreaNegocio } from "@/services/log/log.service.js";
import {
  getAccountTypes,
  getEmailsOwners,
  serverImapService,
  BusinessProcess,
  getCuentaCorreoById,
} from "@/services/cuentasCorreo/cuentasCorreo.service.js";

export default {
  components: {},
  data: () => {
    return {
      required: [(value) => !!value || "Campo Requerido"],
      form: {
        cuenta: null,
        secretId: null,
        servidor: null,
        puerto: null,
        tipoCuenta: null,
        tipoSeguridad: null,
        tipoAutentificacion: null,
        estatus: 1,
        servicioCreacionSolicitudes: null,
        reglaNegocioAplicable: null,
        servicioNotificacionesSalientes: null,
        areaNegocioId: null,
        responsableCuenta: null,
      },
      areaNegocios: [],
      tiposCuenta: [],
      responsableDeCuentas: [],
      reglasNegocio: [],
      serverImapService: serverImapService,
      cuentaEditable: {},
    };
  },
  props: {
    toSave: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    cuentaId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    "form.areaNegocioId"(val) {
      if (val) this.getEmailsOwners();
    },
    "form.servidor"(val) {
      if (val) this.setProviderInfo();
    },
    toSave(val) {
      if (val) {
        this.$refs.cuentaCorreo.validate();
        this.$emit("goTosave", this.$refs.cuentaCorreo.validate(), this.form);
      }
    },
    cuentaId(newVal) {
      console.log("cuentaId changed:", newVal);
      if (newVal) {
        this.getCuentaCorreoById();
      }
    },
  },
  computed: {
    currentReglasNegocio() {
      return this.reglasNegocio.filter(
        (e) => e.areaNegocioId == this.form.areaNegocioId
      );
    },
  },
  mounted() {
    this.getAreaNegocio();
    this.getAccountTypes();
    this.BusinessProcess();
    if (this.cuentaId) {
      this.getCuentaCorreoById(); // Llama a la función si cuentaId ya tiene un valor.
    }
  },
  methods: {
    async getAreaNegocio() {
      this.areaNegocios = await getAreaNegocio();
    },
    async getAccountTypes() {
      this.tiposCuenta = await getAccountTypes();
    },
    async BusinessProcess() {
      this.reglasNegocio = await BusinessProcess();
    },
    async getEmailsOwners() {
      if (!this.cuentaId) this.form.responsableCuenta = null;
      this.responsableDeCuentas = await getEmailsOwners({
        areaNegocioId: this.form.areaNegocioId,
      });
    },
    async getCuentaCorreoById() {
      this.cuentaEditable = await getCuentaCorreoById(this.cuentaId);
      (this.form = {
        cuenta: this.cuentaEditable.cuenta,
        secretId: this.cuentaEditable.secretId,
        servidor: this.cuentaEditable.servidor,
        puerto: this.cuentaEditable.puerto,
        tipoCuenta: this.cuentaEditable.tipoCuenta,
        tipoSeguridad: this.cuentaEditable.tipoSeguridad,
        tipoAutentificacion: this.cuentaEditable.tipoAutentificacion,
        estatus: this.cuentaEditable.estatus,
        servicioCreacionSolicitudes: this.cuentaEditable
          .servicioCreacionSolicitudes,
        reglaNegocioAplicable: this.cuentaEditable.reglaNegocioAplicable ? Number(
          this.cuentaEditable.reglaNegocioAplicable 
        ) : '',
        servicioNotificacionesSalientes: this.cuentaEditable
          .servicioNotificacionesSalientes,
        areaNegocioId: this.cuentaEditable.areaNegocioId,
        responsableCuenta: this.cuentaEditable.responsableCuentaId,
      }),
        console.log("Datos de cuenta obtenidos:", this.cuentaEditable);
    },
    setProviderInfo() {
      let data = this.serverImapService.find(
        (e) => e.servidor == this.form.servidor
      );
      this.form.puerto = data.puerto ?? null;
      this.form.tipoSeguridad = data.seguridad ?? null;
      this.form.tipoAutentificacion = data.seguridad ?? null;
    },
    formatText(item) {
      return `${item.areas} - ${item.departamento} - ${item.proceso}`;
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px !important;
}
</style>
