<template>
  <v-expansion-panels elevation="0" style="padding: 30px; border-radius: 20px">
    <v-expansion-panel>
      <v-expansion-panel-header style="padding: 35px;">
        <h3 style="color:#42352E;">
          Búsqueda
        </h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="filtros.cuenta"
              label="Cuenta"
              outlined
              dense
              color="primary"
              background-color="white"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="filtros.areaNegocioId"
              label="Área de Negocio"
              :items="area_negocios"
              item-text="area_negocio"
              item-value="id"
              outlined
              dense
              color="primary"
              background-color="white"
            >
            <template v-slot:item="{ item }">
              <div>
                <strong>{{ formatText(item) }}</strong>
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <span>
                {{ formatText(item) }}
              </span>
            </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="filtros.estatus"
              label="Estatus"
              :items="status"
              item-text="nombre"
              item-value="id"
              outlined
              dense
              color="primary"
              background-color="white"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="filtros.responsableCuentaId"
              label="Responsable"
              outlined
              dense
              :items="responsableDeCuentas"
              item-text="nombre"
              item-value="id"
              color="primary"
              background-color="white"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" style="text-align: end;">
            <v-btn
              color="primary"
              style="border-radius: 15px;  margin-right: 10px;"
              outlined
              dark
              :loading="loading"
              @click="clearFilters"
            >
              <span>Limpiar filtros</span>
            </v-btn>
            <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="$emit('updateFilters', filtros)"
            >
              <span>Filtrar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { getListadoEstatusEmail, getEmailsOwners } from "@/services/cuentasCorreo/cuentasCorreo.service.js";
import { getAreaNegocio } from "@/services/log/log.service.js";

export default {
  components: {
  },
  data() {
    return {
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      area_negocios: [],
      status: [],
      filtros: {
        cuenta: "",
        areaNegocioId: "",
        estatus: "",
        responsableCuentaId: "",
      },
      responsableDeCuentas:[],
      loading:false
    };
  },
  watch:{
    "filtros.areaNegocioId"(val) {
      if (val) this.getEmailsOwners();
    },
  },

  methods: {
    clearFilters() {
      this.filtros = Object.assign(
        {},
        {
          cuenta: "",
          areaNegocioId: "",
          status: "",
          responsableCuentaId: "",
        }
      );
      this.$emit('restartFiltros')
    },
    async getListadoEstatusEmail() {
      this.status = await getListadoEstatusEmail();
    },
    async getAreaNegocio() {
      this.area_negocios = await getAreaNegocio();
    },
    async getEmailsOwners() {
      if (!this.cuentaId) this.filtros.responsableCuentaId = null;
      this.responsableDeCuentas = await getEmailsOwners({
        areaNegocioId: this.filtros.areaNegocioId,
      });
    },
    formatText(item) {
      return `${item.areas} - ${item.departamento} - ${item.proceso}`;
    },
  },
  mounted() {
    this.getListadoEstatusEmail();
    this.getAreaNegocio();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
